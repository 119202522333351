<template>
  <div>
    <my-header></my-header>
    <div class="companybox">
      <div class="company-header">
        <div class="company-header-top">
          <span
            :style="{
              fontSize: '14px',
              color: '#666',
              padding: '6px',
            }"
          >
            全部结果:
          </span>
          <span
            v-if="companyName"
            class="company-lable"
            v-text="`&quot;${companyName}&quot;`"
          >
          </span>
        </div>
        <!-- 二级菜单显示 -->
        <div
          v-if="!List[0].childList.length"
          class="company-header-box"
          :style="{ height: pagination ? '130px' : '' }"
        >
          <div class="company-header-box-title">分类：</div>
          <div class="company-header-box-value">
            <span v-for="(v, k) of pageList" :key="k">
              <span
                :style="{ color: itemid == v.id ? '#00c69e' : '#007ec0' }"
                @click="goItem(v)"
                >{{ v.name }}</span
              >
            </span>
            <div class="pagin-box">
              <el-pagination
                v-if="pagination"
                background
                layout="prev, pager, next"
                :total="total"
                :page-size="pageSize"
                @current-change="handleCurrentChange"
                :hide-on-single-page="true"
              >
              </el-pagination>
            </div>
          </div>
          <div class="company-header-box-buttom">
            <el-button
              :style="{ marginTop: '15px' }"
              v-text="pagination ? '收起' : '更多'"
              @click="pagination = !pagination"
            ></el-button>
          </div>
        </div>
        <!-- 三级菜单展示 -->
        <div v-else>
          <div
            class="company-header-box"
            :style="{ height: pagination ? '130px' : '', borderBottom: 'none' }"
          >
            <div class="company-header-box-title">分类：</div>
            <div class="company-header-box-value">
              <span v-for="(v, k) of pageList" :key="k">
                <span
                  @click="lookIndex(k)"
                  :style="{ color: itemid == v.id ? '#00c69e' : '#007ec0' }"
                  >{{ v.name }}</span
                >
              </span>
              <div class="pagin-box">
                <el-pagination
                  v-if="pagination"
                  background
                  layout="prev, pager, next"
                  :total="total"
                  :page-size="pageSize"
                  @current-change="handleCurrentChange"
                  :hide-on-single-page="true"
                >
                </el-pagination>
              </div>
            </div>
            <div class="company-header-box-buttom">
              <el-button
                :style="{ marginTop: '15px' }"
                v-text="pagination ? '收起' : '更多'"
                @click="pagination = !pagination"
              ></el-button>
            </div>
          </div>
          <div
            class="company-header-box"
            :style="{ height: paginationtwo ? '130px' : '' }"
          >
            <div class="company-header-box-title">分类：</div>
            <div class="company-header-box-value">
              <span v-for="(v, k) of pageListtwo" :key="k">
                <span
                  @click="goItem(v)"
                  :style="{ color: itemid == v.id ? '#00c69e' : '#007ec0' }"
                  >{{ v.name }}</span
                >
              </span>
              <div class="pagin-box">
                <el-pagination
                  v-if="paginationtwo"
                  background
                  layout="prev, pager, next"
                  :total="childListtotal"
                  :page-size="childListpageSize"
                  @current-change="childListhandleCurrentChange"
                  :hide-on-single-page="true"
                >
                </el-pagination>
              </div>
            </div>
            <div class="company-header-box-buttom">
              <el-button
                :style="{ marginTop: '15px' }"
                v-text="paginationtwo ? '收起' : '更多'"
                @click="paginationtwo = !paginationtwo"
              ></el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="itembox" v-if="!List[0].childList.length">
      <!-- 分类详情 -->
      <div class="goods-list">
        <div
          class="goods-list-box"
          v-for="(item, index4) in itempageList"
          :key="index4"
          @click="goItem(item)"
        >
          <div>
            <img class="goods-list-box-img" :src="item.img" alt="" />
          </div>
          <div class="goods-message-txt">
            <span class="goods-message-title">{{ item.name }}</span>
            <div class="button">查看更多</div>
          </div>
        </div>
      </div>
      <div class="pagin-box-1">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="detailtotal"
          :page-size="detailpageSize"
          @current-change="itemhandleCurrentChange"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </div>
    <div class="itembox" v-else>
      <!-- 分类详情 -->
      <div class="goods-list">
        <div
          class="goods-list-box"
          v-for="(item, index4) in pageListtwo"
          :key="index4"
          @click="goItem(item)"
        >
          <div>
            <img class="goods-list-box-img" :src="item.img" alt="" />
          </div>
          <div class="goods-message-txt">
            <span class="goods-message-title">{{ item.name }}</span>
            <div class="button">查看更多</div>
          </div>
        </div>
      </div>
      <div class="pagin-box-1">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="detailtotal"
          :page-size="detailpageSize"
          @current-change="itemhandleCurrentChange"
          :hide-on-single-page="true"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      //查看店铺商品切换显示状态
      lookcompanyactive: true,
      // 分页显示隐藏
      pagination: false,
      paginationtwo: false,
      // 分类列表
      List: [],
      // 当前页的分类列表
      pageList: [],
      itempageList: [],
      //  url传送的商家名称
      companyName: "",
      // 分类名称分页
      pageSize: 10,
      // 总页数
      total: Number,
      // 分类详情分页
      detailpageSize: 12,
      detailtotal: Number,
      // 记录当前点击的商家id
      itemid: Number,
      companyItem: {},
      // 当前点击的二级菜单
      index: 0,
      // 三级菜单
      itemchildList: [],
      // 三级菜单当前页的分类列表
      pageListtwo: [],
      itempageListtwo: [],
      //  三级菜单分页
      childListpageSize: 8,
      childListtotal: Number,
    };
  },
  methods: {
    // 根据子类id查询指定的商品信息
    async goItem(item) {
      this.itemid = item.id;
      console.log(item);
      const res = await this.$http({
        method: "get",
        url: `front/product/findList?categoryId=${item.id}&pageNum=1&pageSize=10`,
      });
      // 已获取参数，页面未完成
      console.log(res.data);
      this.$router.push({
        path: "/subset",
        query: {
          list: JSON.stringify(res.data.data.list),
          name: JSON.stringify(item.name),
        },
      });
    },
    // 改变当前页触发
    handleCurrentChange(num) {
      this.pageNum = num;
      console.log(num * this.pageSize - this.pageSize);
      this.pageList = this.List.slice(
        num * this.pageSize - this.pageSize,
        num * this.pageSize - this.pageSize + this.pageSize
      );
      console.log(this.pageList);
    },
    itemhandleCurrentChange(num) {
      this.detailpageNum = num;
      console.log(num * this.detaillpageSize - this.detailpageSize);
      this.itempageList = this.List.slice(
        num * this.detailpageSize - this.detailpageSize,
        num * this.detailpageSize - this.detailpageSize + this.detailpageSize
      );
    },
    // 三级菜单改变当前页触发
    childListhandleCurrentChange(num) {
      this.pageListtwo = this.itemchildList[this.index].slice(
        num * this.childListpageSize - this.childListpageSize,
        num * this.childListpageSize -
          this.childListpageSize +
          this.childListpageSize
      );
    },
    async routeritem() {
      this.List = JSON.parse(this.$route.query.list);
      this.total = this.List.length;
      this.detailtotal = this.List.length;
      this.pageList = this.List.slice(0, this.pageSize);
      this.itempageList = this.List.slice(0, this.detailpageSize);
      this.pageList.map((v, k) => {
        this.itemchildList.push(v.childList);
      });
      this.pageListtwo = this.itemchildList[this.index].slice(
        0,
        this.childListpageSize
      );
      this.childListtotal = this.itemchildList[this.index].length;
    },
    lookIndex(k) {
      this.index = k;
      this.pageListtwo = this.itemchildList[this.index].slice(
        0,
        this.childListpageSize
      );
      this.childListtotal = this.itemchildList[this.index].length;
    },
  },
  created() {
    this.routeritem();
  },
};
</script>
<style scoped>
.companybox {
  width: 1150px;
  margin: 0 auto;
  margin-top: 160px;
}
.company-lable {
  font-size: 14px;
  font-weight: 600;
  color: #666;
}
.company-header-box {
  position: relative;
  display: flex;
  height: 70px;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #aaa;
}
.company-header-top {
  margin: 10px 0;
}
.company-header-box .company-header-box-title {
  display: inline-block;
  background: #ddd;
  color: #555;
  padding: 6px;
  flex: 2;
}
.company-header-box .company-header-box-value {
  flex: 18;
  display: flex;
  flex-wrap: wrap;
}
.company-header-box .company-header-box-value span {
  box-sizing: border-box;
  width: 18%;
  padding: 6px;
  color: #005aa0;
  height: 30px;
  cursor: pointer;
}
.company-header-box .company-header-box-buttom {
  flex: 2;
}
.itembox {
  padding-bottom: 80px;
  position: relative;
}
.pagin-box {
  position: absolute;
  bottom: 20px;
  right: 50px;
}
.pagin-box-1 {
  position: absolute;
  bottom: 20px;
  right: 300px;
}
.pagin-box-item {
  margin: 30px 0px 60px 700px;
}
.goods-list {
  min-width: 1100px;
  width: 83.5%;
  display: flex;
  margin-left: 8%;
  margin-top: 30px;
  flex-wrap: wrap;
}
.goods-list-box {
  box-sizing: border-box;
  margin: 6px;
  padding: 5px;
  width: 23.5%;
  height: 350px;
  background-color: white;
  margin: 0 auto;
  margin-top: 10px;
  position: relative;
  border-radius: 6px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.2);
}

.goods-list-box:hover {
  box-shadow: 1px 5px 10px #a4a4a4;
}

.goods-list-box-img {
  width: 65%;
  height: 140px;
  margin: 18%;
  margin-top: 19%;
  border-radius: 4px;
  cursor: pointer;
}
.goods-list-box-img:hover {
  transform: scale(1.05);
}
.goods-message-txt {
  width: 100%;
  height: 68px;
  /* background-color:pink; */
  position: absolute;
  bottom: 15px;
  left: 0;
}
.goods-message-title {
  letter-spacing: 1px;
  line-height: 20px;
  display: block;
  width: 80%;
  height: 39%;
  margin: auto;
  font-size: 14px;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
}
.button {
  padding: 8px;
  background: #0ec64b;
  color: #fff;
  border-radius: 5px;
  text-align: center;
  width: 60%;
  margin: 0 auto;
  cursor: pointer;
  user-select: none;
}
</style>